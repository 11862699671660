import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useEffect, useState } from 'react';
import { axios, cigiAxios } from '../lib/axios';

export type TokenContextType = {
	token: string;
	userId: string;
	// setToken: React.Dispatch<string>;
};

export const TokenContext = createContext<TokenContextType>({
	token: '',
	userId: '',
});

type AuthProviderProps = {
	children: React.ReactNode;
};

export const TokenProvider = ({ children }: AuthProviderProps) => {
	const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
	const [token, setToken] = useState<string>('');
	const [userId, setUserId] = useState<string>('');

	useEffect(() => {
		async function updateTokenState() {
			const newToken = await getAccessTokenSilently();
			setToken(newToken);
			axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
			cigiAxios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
		}

		if (isAuthenticated) {
			// eslint-disable-next-line no-void
			void updateTokenState();
			if (user) {
				const id = user.sub;
				if (id) {
					setUserId(id);
				}
			}
		}
	}, [user, isAuthenticated]);

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<TokenContext.Provider value={{ token, userId }}>
			{children}
		</TokenContext.Provider>
	);
};
